import React, {useState, useEffect} from 'react';
import axios from 'axios';
import FriendItem from '../components/FriendItem';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";

const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log(1);
      alert('Success')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }


const Friends = ({tg}) => {
    const { t } = useTranslation();
    const [userId, setUserId] = useState(null);
    const [referralLink, setReferralLink] = useState('');

    const [list, setList] = useState(null);
    const [countRef, setCountRef] = useState(null);
    const [currencies, setCurrencies] = useState(null);
    const [upliner, setUpliner] = useState(false);

    useEffect(() => {
        let tmpUserId = ''
        if (tg && tg.initDataUnsafe && tg.initDataUnsafe.user) {
            const user = tg.initDataUnsafe.user;
            if (user && user.id) {
                tmpUserId = user.id;
                setUserId(user.id);
            }
            fetchFriends();
        }
        setReferralLink(`https://t.me/tonext_app_bot?start=${tmpUserId}`);
    }, [tg]);

  const fetchFriends = async () => {
      const initData = tg && tg.initData ? tg.initData : null;
          const response = await axios.get(`${process.env.REACT_APP_API_URL}getFriends.php`, 
            {
              headers: {
                'Authorization': initData,
                'Content-Type': 'application/json'
            },
          }
          )
          .then(response => {
            setList(response.data.friendsIncome);
            setCountRef(response.data.friendsCount);
            setCurrencies(response.data.currencies);
            setUpliner(response.data.upliner)
          }).catch(error => {
            console.log('Error fetching data:', error);
          });
  };


    return (
        <>
            <main>
                <section className="referral-section">
                    <div className="container">

                        <div className="referral-section__box">
                            <div className="referral-section__wrapp">
                            <Header tg={tg}/>
                                <h1 className="title">
                                    <svg width='24' height='24'>
                                        <use href='img/sprite/sprite.svg#users'></use>
                                    </svg>
                                    {t("Friends")}
                                </h1>

                                <div class="referral-section__wrapp">
                                    <form class="copy-form">
                                        <label class="copy-form__label" for="address_3" data-parent-copy>
                                            <input onClick={() => copyText(referralLink)} readonly type="text" name="address_3" id="address_3" class="copy-form__input" value={referralLink}/>
                                                <button type="button" class="copy-btn" onClick={() => copyText(referralLink)}>
                                                    <img width='24' height='24' src='img/sprite/copy.svg' alt='image'/>
                                                </button>
                                        </label>
                                    </form>

                                    <div class="main-info">
                                        <div class="main-info__col">
                                            <span class="main-info__value"><b>5%</b> 1-{t("st")} {t("level")}</span>
                                        </div>
                                        <div class="main-info__col main-info__col--mode">
                                            <span class="main-info__value"><b>3%</b> 2-{t("st")} {t("level")}</span>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="referral-section__wrapp mode">
                                <ul className="history-list">
                                    <li className="history-list__row history-list__row--head" key={0}>
                                        <span className="history-list__name">{t('Date')}</span>
                                        <span className="history-list__name">{t('User')}</span>
                                        <span className="history-list__name">{t('Amount')}</span>
                                        <span className="history-list__name">{t('Profit')}</span>
                                    </li>
                                    {currencies && (
                                    <>
                                        {list.map((item) => (
                                                    <FriendItem
                                                        item={item}
                                                        currency={currencies[item.currency_id - 1]}
                                                    />
                                                ))}
                                            </>
                                        )}
                                </ul>

                            </div>


                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Friends;