import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from "../lang/TranslationContext";

const Header = ({tg, data, nowMoney}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="main-top">
                <div className="main-top__bg">
                    <img width="641" height="66" src="img/index/bg.png" alt="image"/>
                </div>
                <a href="https://telegra.ph/FAQ-TONEXT-09-15" target="_blank" className="main-top__btn">
                    <svg width="24" height="24">
                        <use href="img/sprite/sprite.svg#help"></use>
                    </svg>
                    FaQ
                </a>
                <div className="main-top__box">
                    {/* <div className="main-top__image"> */}
                        {/* <img width="31" height="31" src="img/index/user.png" alt="image"/> */}
                    {/* </div> */}
                    <span className="main-top__user">{tg && tg.initDataUnsafe && tg.initDataUnsafe.user && (tg.initDataUnsafe.user.username)}</span>
                </div>
                <a href="https://t.me/tonext_support" className="main-top__btn mode">
                    <img width="59" height="41" src="img/index/faq.png" alt="image"/>
                </a>
            </div>

{ nowMoney && (<div className="wallet-info wallet-info--mode">
                                <div className="main-total">
                                    <div className="main-total__icon">
                                        <img width="33" height="33" src="img/sprite/ton.svg" alt="image"/>
                                    </div>
                                    <span className="main-total__value">
                                      <span>{Number(nowMoney).toFixed(9)}</span> <b>TON</b>
                                    </span>
                                </div>
                                <div className="wallet-info__bottom">
                                    <span className="wallet-info__icon">
                                      <svg width="24" height="24">
                                        <use href="img/sprite/sprite.svg#eco"></use>
                                      </svg>
                                    </span>
                                    <span className="wallet-info__value"><b>{data && Number(data.power).toFixed(2)}</b> th/s</span>
                                </div>
                            </div>)}
            
        </>
    );
};

export default Header;
