import React, {useEffect, useState} from 'react';
import axios from 'axios';
import DepositItem from '../components/DepositItem';
import {useTranslation} from "../lang/TranslationContext";

const Deposit = ({tg}) => {
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const fetchWallets = async () => {
      const initData = tg && tg.initData ? tg.initData : null;
          const response = await axios.get(`${process.env.REACT_APP_API_URL}getWallets.php`, 
            {
              headers: {
                'Authorization': initData,
                'Content-Type': 'application/json'
            },
          }
          )
          .then(response => {
            setCurrencies(response.data.currencies);
            setUser(response.data.user);
          }).catch(error => {
            console.log('Error fetching data:', error);
          });
  };


  useEffect(() => {
      fetchWallets();
  }, [tg]);

    return (
        <>
        <main>
            <section class="boost-section">
              <div class="container">
                <div class="boost-section__box">
                  <div class="main-top">
                    <h1 class="title">{t('Deposit')} <svg width="24" height="24">
                      <use href="img/sprite/sprite.svg#i"></use>
                    </svg>
                    </h1>
                  </div>
                  <form class="search">
                    <label class="search__label" for="search">
                      <input class="search__input" id="search" name="search" placeholder="Search" type="text"/>
                      <button class="search__btn">
                        <svg width="24" height="24">
                          <use href="img/sprite/sprite.svg#search"></use>
                        </svg>
                      </button>
                    </label>
                  </form>
                  <ul class="deposit-list">
                    {currencies && (
                      <>
                      {currencies.filter(currency => currency.active).map((currency, index) => (
                          <DepositItem key={currency.id} currency={currency} user={user} tg={tg} /> 
                      ))}
                      </>
                  )}
                  </ul>
                </div>
              </div>
            </section>
          </main>
        </>
    );
};

export default Deposit;
