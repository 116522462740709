import {Route, Routes} from "react-router-dom";
import React from "react";
import Home from "../pages/Home";
import Translation from "../pages/Translation";
import Deposit from "../pages/Deposit";
import Friends from "../pages/Friends";
import Withdraw from "../pages/Withdraw";
import Settings from "../pages/Settings";
import Tasks from "../pages/Tasks";


const RouterList = ({tg, openModal, setCurrentBalance, setCurrentWallet, actualUser, history, fetchHistory}) => {

    return (
        <Routes>
            <Route exact path="/" element={<Home  tg={tg} openModal={openModal} setCurrentBalance={setCurrentBalance} setCurrentWallet={setCurrentWallet}/>} />
            <Route path="/translation" element={<Translation />} />
            <Route path="/deposit" element={<Deposit  tg={tg}/>} />
            <Route path="/friends" element={<Friends  tg={tg}/>} />
            <Route path="/withdraw" element={<Withdraw   tg={tg} user={actualUser} history={history} fetchHistory={fetchHistory}  openModal={openModal} setCurrentBalance={setCurrentBalance} setCurrentWallet={setCurrentWallet}/> } />
            <Route path="/settings" element={<Settings tg={tg}/>} />
            <Route path="/tasks" element={<Tasks tg={tg}/>} />
        </Routes>
    );
};

export default RouterList;

