import React, {useEffect, useState} from 'react';

const Settings = ({tg}) => {
    const [user, setUser] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState('./img/index/user.png');

    useEffect(() => {

        if(tg && tg.initDataUnsafe && tg.initDataUnsafe.user && tg.initDataUnsafe.user.language_code) {
            const userData = tg.initDataUnsafe.user;
            if (userData) {
                setUser(userData);
                const userId = userData.id;
                const avatarUrl = `https://t.me/i/userpic/320/${userId}.jpg`;

                // Проверка доступности аватара
                fetch(avatarUrl)
                    .then(response => {
                        if (response.ok) {
                            setAvatarUrl(avatarUrl);
                        } else {
                            console.log('Avatar not found');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching avatar:', error);
                    });
            } else {
                console.log('User data not available');
            }
        }
    }, [tg]);


    return (
        <>
            <main>

                <section className="boost-section">
                    <div className="container">
                        <div className="boost-section__box">
                            <div className="main-top">
                                <h1 className="title">
                                    Setting
                                    <svg width='24' height='24'>
                                        <use href='img/sprite/sprite.svg#i'></use>
                                    </svg>
                                </h1>
                            </div>

                            <div className="personal-card">
                                <span className="personal-card__title">Personal Data:</span>

                                <div className="personal-card__box">
                                    <div className="personal-card__image">
                                        <img width='45' height='45' src={avatarUrl} alt="image"/>
                                    </div>

                                    <span className="personal-card__name">@Said.Ali 85</span>
                                    <p>said.ali 85@gmail.com</p>

                                    <button type={"button"} className="edit-btn">
                                        <svg width='20' height='20'>
                                            <use href='img/sprite/sprite.svg#edit'></use>
                                        </svg>
                                    </button>

                                </div>
                            </div>

                            <form className="main-form">

                                <div className="main-form__inner">
                                    <span className="main-form__title">Nickname</span>
                                    <div className="main-form__label" htmlFor="name">
                                        <input className="main-form__input" type="text" name="name" id="name" placeholder="@Said.Ali 85"/>
                                            <button type={"button"} className="edit-btn edit-btn--mode">
                                                <svg width='20' height='20'>
                                                    <use href='img/sprite/sprite.svg#edit'></use>
                                                </svg>
                                            </button>
                                    </div>
                                </div>

                                <div className="main-form__inner">
                                    <span className="main-form__title">Email</span>
                                    <div className="main-form__label" htmlFor="mail">
                                        <input className="main-form__input" type="email" name="mail" id="mail" placeholder="said.ali 85@gmail.com"/>
                                            <button type={"button"} className="edit-btn edit-btn--mode">
                                                <svg width='20' height='20'>
                                                    <use href='img/sprite/sprite.svg#edit'></use>
                                                </svg>
                                            </button>
                                    </div>
                                </div>
                                <div className="main-form__inner">
                                    <span className="main-form__title">Password</span>
                                    <div className="main-form__label" htmlFor="pass">
                                        <input className="main-form__input" type="text" name="pass" id="pass" placeholder="User _)21q32"/>
                                            <button type={"button"} className="edit-btn edit-btn--mode">
                                                <svg width='20' height='20'>
                                                    <use href='img/sprite/sprite.svg#edit'></use>
                                                </svg>
                                            </button>
                                    </div>
                                </div>

                                <button type={"button"} className="main-btn">Update</button>
                            </form>


                        </div>
                    </div>
                </section>

            </main>
        </>
    );
};

export default Settings;
