import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from "../lang/TranslationContext";

const Footer = (page, setActivePage) => {
    const { t } = useTranslation();
    return (
        <ul className="bar">
            <li className="bar__item">
                <NavLink to={"/"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                    <span className="bar__icon">
                      <svg width='24' height='24'>
                        <use href='img/sprite/sprite.svg#icon1'></use>
                      </svg>
                    </span>
                    {t('Game')}
                </NavLink>
            </li>

            <li class="bar__item">
                <NavLink to={"/withdraw"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                <span class="bar__icon">
                  <svg width='24' height='24'>
                    <use href='img/sprite/sprite.svg#icon2'></use>
                  </svg>
                </span>
                    {t('Wallet')}
                </NavLink>
            </li>

            <li className="bar__item ">
                <NavLink to={"/tasks"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                    <span className="bar__icon">
                      <svg width='24' height='24'>
                        <use href='img/sprite/sprite.svg#icon3'></use>
                      </svg>
                    </span>
                    {t('Missions')}
                </NavLink>
            </li>

            <li className="bar__item">
                <NavLink to={"/friends"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                    <span className="bar__icon">
                       <svg width='24' height='24'>
                        <use href='img/sprite/sprite.svg#icon4'></use>
                      </svg>
                    </span>
                    {t('Friends')}
                </NavLink>
            </li>
        </ul>
    );
};

export default Footer;
