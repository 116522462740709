import React, {useState} from 'react';
import {useTranslation} from "../lang/TranslationContext";

const copyText = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    alert('Success')
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}


const DepositItem = ({currency, user, tg}) => {
  const [wallet, setWallet] = useState(null);
  const { t } = useTranslation();



  const fetchCreateWallet = async () => {
    try {
      const initData = tg && tg.initData ? tg.initData : null;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/createWallet.php`, {
                    method: 'POST',
                    headers: {
                            'Authorization': initData || '',
                            'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                      'currency_id': Number(currency.id),
                    }),
            });

            const result = await response.json();
            if (result.status === 'success') {
              let new_wallet = result.wallet;
              if(result.tag != '') {
                new_wallet += ' TAG: ' + result.tag
              }
              setWallet(new_wallet)
            } else {
            }
    } catch (error) {
        console.log(error)
    }
};

    let short = currency.short.toLowerCase();
    if(short == 'btc') {
        short = 'btcc'
    }

    const icon = "img/sprite/sprite.svg#" + short;
    
    return (
        <>
         <li class="deposit-list__item" key={currency.id}>
                      <div class="deposit-card">
                        <div class="deposit-card__box">
                          <div class="deposit-card__image">
                            <svg width="24" height="24">
                              <use href={icon}></use>
                            </svg>
                          </div>
                          <span class="deposit-card__title">{currency.short}</span>
                          <p>{currency.name}</p>
                        </div>
                        
                        <form class="main-form adress" data-parent>
                          {!wallet ? (
                            <button type="button"  onClick={fetchCreateWallet} class="main-btn" data-copy-btn>{t("Generate Wallet")}</button>
                          ) : (<>
                          
                          <label class="main-form__label" for="address">
                              <input readonly="" type="text" name="address" id="address" class="main-form__input" value={wallet}/>
                              <button type="button" class="copy-btn" onClick={() => copyText(wallet)}>
                              <img width="24" height="24" src="img/sprite/copy.svg" alt="image"/>
                              </button>
                          </label>
                    
                          <button type="button"  onClick={() => copyText(wallet)} class="main-btn" data-copy-btn="" style={{display:"none"}}>{t("Copy")}</button>
                  </>)}
                          
                        </form>
                      </div>
                    </li>
                    </>
    );
};

export default DepositItem;
