import React from 'react';
import {useTranslation} from "../lang/TranslationContext";

const Translation = () => {
  const { t } = useTranslation();
    return (
        <>
            <main>
  <section class="translation-section">
    <div class="container">
      <div class="translation-section__box">
        <div class="main-top">
          <h1 class="title">{t('Translation')} <svg width="24" height="24">
            <use href="img/sprite/sprite.svg#i"></use>
          </svg>
          </h1>
        </div>
        <ul class="translation-list">
          <li class="translation-list__item">
          <a href="https://public.ivideon.com/camera/100-EsCMoJX2N850Z6qm65HRAI/0/?lang=ru"  target="_blank" data-fancybox class="translation-card">

          <span class="translation-card__bg">
                    <img width="362" height="220" src="img/index/live.png" alt="image"/>
                  </span>          <span class="translation-card__icon">
                    <svg width="109" height="22">
                      <use href="img/sprite/sprite.svg#icon2"></use>
                    </svg>
                  </span>
              <button class="play-btn">
                <svg class="play-btn__icon" width="12" height="15">
                  <use href="img/sprite/sprite.svg#play"></use>
                </svg>
                <svg class="play-btn__icon mode" width="13" height="15">
                  <use href="img/sprite/sprite.svg#pause"></use>
                </svg>
              </button>
              <p>{t('Camera 1. Live Mining Translation.')}</p>
            </a>
          </li>
          <li class="translation-list__item" style={{display: "none"}}>
            <a href="javascript:;" data-fancybox class="translation-card">
                  {/* <span class="translation-card__bg">
                    <img width="362" height="220" src="img/index/img2.png" alt="image"/>
                  </span> */}
              <span class="translation-card__icon">
                    <svg width="109" height="22">
                      <use href="img/sprite/sprite.svg#icon2"></use>
                    </svg>
                  </span>
              {/* <button class="play-btn">
                <svg class="play-btn__icon" width="12" height="15">
                  <use href="img/sprite/sprite.svg#play"></use>
                </svg>
                <svg class="play-btn__icon mode" width="13" height="15">
                  <use href="img/sprite/sprite.svg#pause"></use>
                </svg>
              </button> */}
              <p>{t('Camera 2. Will be available soon!')}</p>
            </a>
          </li>
          <li class="translation-list__item">
            <a href="javascript:;" data-fancybox class="translation-card">

            
          <span class="translation-card__icon">
                    <svg width="109" height="22">
                      <use href="img/sprite/sprite.svg#icon2"></use>
                    </svg>
                  </span>
            <div class="iv-embed" style={{margin:"0 auto",padding:0,border:0,width:"100%"}}><div class="iv-v" style={{display:"block",margin:0,padding:"1px",border:0,background:"#000"}}><iframe class="iv-i" style={{display:"block",margin:0,padding:0,border:0, width: "100%", height: "280px"}} src="https://open.ivideon.com/embed/v3/?server=100-EsCMoJX2N850Z6qm65HRAI&amp;camera=0&amp;width=&amp;height=&amp;lang=ru"  frameborder="0" allow="autoplay; fullscreen; clipboard-write; picture-in-picture"></iframe></div><div class="iv-b" style={{display:"block",margin:0,padding:0,border:0}}><div style={{clear:"both",height:0,overflow:"hidden"}}>&nbsp;</div><script src="https://open.ivideon.com/embed/v3/embedded.js"></script></div></div>
            <p>{t('Camera 1. Live Mining Translation.')}</p>
            </a>
            </li>
        </ul>
      </div>
    </div>
  </section>
</main>
        </>
    );
};

export default Translation;
